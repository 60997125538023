import { observable } from "mobx";
import AuthRequest from "http/auth";
import jwt_decode from "jwt-decode";
import Moment from "moment";
import "moment/locale/ko";
import toast from "common/components/atoms/Toast";
import _ from "lodash";
import { calcDiffDate } from "common/components/common";
const authRequest = new AuthRequest();

const authStore = observable({
    hashCode: "",                                       // 해시코드
    isExpire: false,                                    // 스케줄 등록기간 만료 여부
    isLogin: false,                                     // 로그인 여부
    hashInfo: {},                                       // 해시 정보
    info: {},                                           // 로그인한 사용자의 정보
    expireScheduleDate: Moment("2099-12-31 23:59:59"),  // 스케줄 등록 만료일자
    //초기화
    init() {
        console.log(this)
    },
    //해시코드를 이용하여 정보 얻기
    getInfo({ hashcode }, callback, errorCallback) {
        authRequest.getInfo(hashcode, response => {
            /*
                "data": {
                    "academyId": 62,
                    "academyName": "리버스랩",
                    "parentPhone": "010-0000-0001",
                    "studentId": 251,
                    "studentName": "옐로우",
                    "expiredAt": "2022-04-28T11:53:38"
                }
            */
            if (response.status === 200) {
                const hashInfo = response.data.data;
                this.setValue("hashInfo", hashInfo);
                this.setValue("expireScheduleDate", Moment(hashInfo.expiredAt).format("YYYY-MM-DD HH:mm:ss"));
                window.sessionStorage.setItem("hashInfo", JSON.stringify(hashInfo));
                callback(response);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
                callback(response);
            }

        }, error => {
            errorCallback(error)
        });
    },
    // 인증코드 전송
    callAuth({ hashcode }, callback, errorCallback) {
        authRequest.callAuth(hashcode, response => {
            if (response.status === 200 && response.data.code === "OK") {
                callback(response);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });;
                callback(response);
            }

        }, error => {
            errorCallback(error);
        });
    },
    // 인증코드 유효성 검사
    codeValid({ code }, callback, errorCallback) {
        authRequest.codeValid({
            hashcode: this.hashcode,
            inputValidCode: code
        }, response => {
            if (response.status === 200 && response.data.code === "OK") {
                /*
                    exp: 1619509675
                    expireAt: 1619509675210
                    iat: 1619423275
                    id: 1
                    type: "Bearer"
                    userType: "COLLECT_WEB"
                */
                const info = jwt_decode(response.data.data);
                console.log(info);
                window.sessionStorage.setItem("access_token", response.data.data);
                this.setValue("info", info);
                this.setValue("isLogin", true);
                callback(response);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
                callback(response);
            }

        }, error => {
            errorCallback(error);
        });
    },
    //로그아웃
    logout() {
        this.setValue("isLogin", false);
        this.setValue("info", {});
        this.setValue("hashInfo", {});
        window.sessionStorage.setItem("access_token", "");
        window.sessionStorage.setItem("hashInfo", "");

    },
    //로그인 인증 유효성 검사
    authCheck({ history }) {

        /* 작업을 위해 잠시 예외처리 */
        // if (_.includes(["/", "/test", "/schedule", "/scheduleAdd", "/scheduleAdd/1", "/scheduleAdd/1,2", "/scheduleDetail/1"], window.location.pathname)) {
        //     this.setValue("isLogin", true);
        //     return false;
        // }
        if (_.includes(["/expire"], window.location.pathname)) {
            return false;
        }

        const access_token = window.sessionStorage.getItem("access_token");
        const hashInfo = window.sessionStorage.getItem("hashInfo");

        if (access_token !== null && access_token !== "" && _.isEmpty(hashInfo) === false) {
            const hashInfoParse = JSON.parse(hashInfo);
            const info = jwt_decode(access_token);
            const exp = Moment(info.exp * 1000).format("YYYY-MM-DD HH:mm:ss");                          // 만료일자
            const now = Moment(new Date()).format("YYYY-MM-DD HH:mm:ss");                               // 현재일자
            //const iat = Moment(info.iat * 1000).format("YYYY-MM-DD HH:mm:ss");                        // 토근발급일자
            const expireScheduleDate = Moment(hashInfoParse.expiredAt).format("YYYY-MM-DD HH:mm:ss");   // 스케줄등록만료일자

            //만료일자 지남
            if (now > exp) {
                this.setValue("isLogin", false);
                this.setValue("info", {});
                this.setValue("hashInfo", {});
                window.sessionStorage.setItem("access_token", "");
                window.sessionStorage.setItem("hashInfo", "");
                history.replace("/login");

            } else {
                this.setValue("isLogin", true);
                this.setValue("info", info);
                this.setValue("expireScheduleDate", expireScheduleDate);
                this.setValue("hashInfo", hashInfoParse);

                if (history.location.pathname === "/login") {
                    history.replace("/");
                }
            }
        } else {
            this.setValue("isLogin", false);
            this.setValue("info", {});
            this.setValue("hashInfo", {});
            window.sessionStorage.setItem("access_token", "");
            window.sessionStorage.setItem("hashInfo", "");
            history.replace("/login");
        }

    },
    // 스케줄 등록만료기간 체크
    authExpireCheck({ history }) {
        const timeLeft = calcDiffDate(this.expireScheduleDate);

        if (timeLeft.isOver === true) {
            this.setValue("isExpire", true);
            history.replace("/expire");

        } else {
            this.setValue("isExpire", false);
            if (window.location.pathname === "/expire") {
                this.setValue("isLogin", false);
                this.setValue("info", {});
                window.sessionStorage.setItem("access_token", "");
                // history.replace("/login");
            }
        }
    },
    //데이터 저장
    setValue(key, value) {
        this[key] = value;
    },
})
export { authStore };